import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
export const AdminPage = () => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [userMap, setUserMap] = useState(new Map());
  useEffect(() => {
    axios
      .get('/api/admin/users?include_inactive=true')
      .then((result) => {
        for (const user of result.data) {
          userMap.set(user.id, user);
        }
        setUserMap(new Map(userMap));
      })
      .catch((err) => {
        setSnackMessage('Failed to load the page');
        setSnackOpen(true);
      });
  }, []);
  return (
    <>
      <Paper sx={{ padding: '10px' }} variant="outlined">
        <Typography variant="h5">All users</Typography>
        <Table>
          <TableBody>
            {[...userMap.keys()].map((id) => {
              return (
                <TableRow
                  key={id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{userMap.get(id).name}</TableCell>
                  <TableCell>{userMap.get(id).username}</TableCell>
                  <TableCell>
                    <FormControl sx={{ minWidth: '20%' }}>
                      <InputLabel id="user-group">User Group</InputLabel>
                      <Select
                        id="user-group"
                        label="User Group"
                        value={userMap.get(id).group}
                        onChange={(e) => {
                          userMap.get(id).group = e.target.value;
                          axios
                            .put('/api/admin/account', userMap.get(id))
                            .catch((err) => {
                              setSnackMessage('Failed to update user');
                              setSnackOpen(true);
                            });
                          setUserMap(new Map(userMap));
                        }}
                      >
                        <MenuItem value={'admin'}>Admin</MenuItem>
                        <MenuItem defaultChecked value={'ssc_user'}>
                          SSC User
                        </MenuItem>
                        <MenuItem value={'bd'}>Business Development</MenuItem>
                        <MenuItem value={'loan_ops_uw'}>Loan Ops + UW</MenuItem>
                        <MenuItem value={'loan_ops'}>Loan Ops</MenuItem>
                        <MenuItem value={'fin_ops'}>Fin Ops</MenuItem>
                        <MenuItem value={'uw'}>Underwriting</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <Typography>Active</Typography>
                      <Checkbox
                        checked={userMap.get(id).active}
                        onChange={() => {
                          userMap.get(id).active = !userMap.get(id).active;
                          axios
                            .put('/api/admin/account', userMap.get(id))
                            .catch((err) => {
                              setSnackMessage('Failed to update user');
                              setSnackOpen(true);
                            });
                          setUserMap(new Map(userMap));
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <Snackbar
        open={snackOpen}
        message={snackMessage}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
      />
    </>
  );
};
