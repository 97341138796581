import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, SwipeableDrawer, Typography, SxProps, Theme, Tabs, Tab, Button, useMediaQuery } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FileType } from '../helpers/types';
import { shortenFilename } from '../helpers/helpers';
import { StyledTab } from './StyledComponents/StyledTab';
import { StyledSwipeableDrawer } from './StyledComponents/StyledSwipeableDrawer';
import { StyledTabs } from './StyledComponents/StyledTabs';
import { StyledHTMLObject } from './StyledComponents/StyledHTMLObject';
import { ShowIf } from './ConditionalRender/ShowIf';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';

interface FilePreviewDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  customSx?: SxProps<Theme>;
}

export const FilePreviewDrawer = (
  {
    isDrawerOpen,
    toggleDrawer,
    customSx,
  }: FilePreviewDrawerProps
) => {
  const { state: { selectedFile, openedFiles, loanSummaryExpanded }, removeFileFromPreview, addFileToPreview, setSelectedFile, resetOpenedFiles } = useRequiredDocuments();
  const [selectedTab, setSelectedTab] = useState(0);

  const isBreakpoint = useMediaQuery('(max-width: 1807px)');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const newSelectedFile = openedFiles[newValue];
    addFileToPreview(newSelectedFile);
  };

  const fileUrl = `/api/v1/loans/file/${selectedFile?.id}`;

  const handleRemoveFile = (file: FileType) => {
    removeFileFromPreview(file);
    const updatedFiles = openedFiles.filter(f => f.id !== file.id);
    const newSelectedFile = updatedFiles[0] ?? null;
    setSelectedFile(newSelectedFile);
    setSelectedTab(newSelectedFile ? updatedFiles.indexOf(newSelectedFile) : 0);
  }

  const handleCloseDrawer = useCallback(() => {
    // Remove all files
    resetOpenedFiles()

    // Reset selected tab
    setSelectedTab(0);

    // Close the drawer
    toggleDrawer();
  }, [])

  useEffect(() => {
    if (!openedFiles.length) {
      setSelectedFile(null);
      setSelectedTab(0);
    } else {
      setSelectedFile(selectedFile);
      const selectedIndex = openedFiles.findIndex(file => file.id === selectedFile?.id);
      setSelectedTab(selectedIndex !== -1 ? selectedIndex : 0);
    }
  }, [openedFiles, selectedFile, handleCloseDrawer]);

  return (
    <StyledSwipeableDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      onOpen={toggleDrawer}
      variant="permanent" // This is ABSOLUTELY necessary in order for the component to be always open and allow opening, closing, and interacting with the page
      PaperProps={{
        sx: { 
          ...customSx, 
          transition: 'none', 
          transitionDuration: '0s', 
          '& .MuiPaper-root': { 
            transition: 'none', 
            transitionDuration: '0s', 
          }, 
          '& .MuiDrawer-docked': { 
            transition: 'none', 
            transitionDuration: '0s' 
          }, 
          '& .MuiDrawer-paper': { 
            transition: 'none', 
            transitionDuration: '0s' 
          }, 
        },
        'data-testid': 'drawer-paper',
        transition: 'none',
        transitionDuration: '0s',
      }}
      ModalProps={{ keepMounted: true }}
    >
      <Box sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} role="presentation">
        <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>Files Preview</Typography>
        <IconButton onClick={handleCloseDrawer} sx={{ px: 0 }}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto', // Enable scrolling inside the content area
          padding: 2,
          paddingTop: 0,
        }}
      >
        <ShowIf every={[openedFiles.length > 0]}>
          <>
            <StyledTabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="file tabs"
            >
              {
                openedFiles.map((file, index) => (
                  <StyledTab key={file.id} label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {shortenFilename(file.file_name, 30)}
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveFile(file)}
                        sx={{
                          p: '5px',
                          cursor: 'pointer',
                          fontSize: '10px',
                        }}
                      >
                        <CloseRoundedIcon fontSize="small" sx={{ fontSize: '14px' }} />
                      </IconButton>
                    </Box>
                  }
                  />
                ))
              }
            </StyledTabs>
            {selectedFile && (
              <Box
                sx={{
                  height: loanSummaryExpanded  ? '75%' : '95%',
                }}
              >
                <Box
                  sx={{
                    mt: 2,
                    border: '1px solid #DDD',
                    borderRadius: '4px',
                    padding: '10px',
                    backgroundColor: '#F9F9F9',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: isBreakpoint ? '71%' : '85%',
                  }}
                >
                  {selectedFile.file_name.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/) && (
                    <img
                      src={fileUrl}
                      alt={selectedFile.file_name}
                      style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                    />
                  )}
                  {selectedFile.file_name.toLowerCase().endsWith('.pdf') && (
                    <StyledHTMLObject data={fileUrl} >
                      <p>PDF preview is not available. <a href={fileUrl}>Download PDF</a></p>
                    </StyledHTMLObject>
                  )}
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => window.open(fileUrl, '_blank')}
                  >
                    Open in Tab
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    href={fileUrl}
                    download={selectedFile?.file_name}
                    sx={{
                      color: 'white !important',
                    }}
                  >
                    Download File
                  </Button>
                </Box>
              </Box>
            )}
          </>
        </ShowIf>
        <ShowIf every={[openedFiles.length === 0]}>
          <Box
            mt={2}
            sx={{
              border: '1px solid #4476AD',
              borderRadius: '6px',
              p: '.5rem',
              backgroundColor: '#D8E4F1',
            }}
          >
            <Typography fontSize={'14px'} color={'#4476AD'}>
              Select files to preview.
            </Typography>
          </Box>
        </ShowIf>
      </Box>
    </StyledSwipeableDrawer>
  );
};