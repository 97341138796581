import { RequiredDocument, FileType, DocumentType, CustomDocumentType } from "../helpers/types";
import { SET_REQUIRED_DOCUMENTS, SET_OPENED_FILES, SET_SELECTED_FILE, SET_DOCUMENT_TYPES, ADD_REQUIRED_DOCUMENT, SET_LOAN_SUMMARY_EXPANDED } from "../actions/requiredDocumentsActions";

// These actions are used to update the state of the required documents. 
// They are used in the RequiredDocumentsProvider (Context) component, which dispatches the actions with the payload.
export type RequiredDocumentsActions =
  | { type: typeof SET_REQUIRED_DOCUMENTS; payload: { requiredDocuments: RequiredDocument[] } }
  | { type: typeof ADD_REQUIRED_DOCUMENT; payload: { requiredDocument: RequiredDocument } }
  | { type: typeof SET_DOCUMENT_TYPES; payload: { documentTypes: Record<DocumentType['id'], CustomDocumentType> } }
  | { type: typeof SET_OPENED_FILES; payload: { openedFiles: FileType[] } }
  | { type: typeof SET_SELECTED_FILE; payload: { selectedFile: FileType | null } }
  | { type: typeof SET_LOAN_SUMMARY_EXPANDED; payload: { loanSummaryExpanded: boolean } }

// This is the state of the Required Documents context. It is used in the RequiredDocumentsProvider (Context) component.
export type RequiredDocumentsState = {
  requiredDocuments: RequiredDocument[]
  openedFiles: FileType[]
  selectedFile: FileType | null
  documentTypes: Record<DocumentType['id'], CustomDocumentType>
  loanSummaryExpanded: boolean
}

// This is the state with we initialize the context with.
export const initialState: RequiredDocumentsState = {
  requiredDocuments: [],
  openedFiles: [],
  selectedFile: null,
  documentTypes: {},
  loanSummaryExpanded: true
}

// This is the reducer function that updates the state of the Required Documents context based on the action dispatched.
export const requiredDocumentsReducer = (
  state: RequiredDocumentsState = initialState,
  action: RequiredDocumentsActions
) => {

  const { type, payload } = action;

  switch (type) {
    case SET_REQUIRED_DOCUMENTS:
      return {
        ...state,
        requiredDocuments: payload.requiredDocuments
      };
    case SET_OPENED_FILES:
      return {
        ...state,
        openedFiles: payload.openedFiles
      };
    case SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: payload.selectedFile
      };
    case SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: payload.documentTypes
      };
    case ADD_REQUIRED_DOCUMENT:
      return {
        ...state,
        requiredDocuments: [...state.requiredDocuments, payload.requiredDocument]
      };
    case SET_LOAN_SUMMARY_EXPANDED:
      return {
        ...state,
        loanSummaryExpanded: !payload.loanSummaryExpanded
      };
    default:
      return state;
  }

}