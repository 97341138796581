import React, { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { ADD_REQUIRED_DOCUMENT, SET_DOCUMENT_TYPES, SET_LOAN_SUMMARY_EXPANDED, SET_OPENED_FILES, SET_REQUIRED_DOCUMENTS, SET_SELECTED_FILE } from '../actions/requiredDocumentsActions';
import { CustomDocumentType, DocumentType, FileType, RequiredDocument } from '../helpers/types';
import { initialState, RequiredDocumentsActions, requiredDocumentsReducer, RequiredDocumentsState } from '../reducers/requiredDocumentsReducer';
import {
  createRequiredDocument as createRequiredDocumentService,
  fetchDocumentTypes as fetchDocumentTypesService,
  getApplicationRequiredDocuments,
} from '../services/requiredDocumentsService';

type RequiredDocumentsProviderProps = {
  children: ReactNode;
}

type RequiredDocumentsContextProps = {
  state: RequiredDocumentsState;
  dispatch: Dispatch<RequiredDocumentsActions>;
  fetchRequiredDocuments: (appId: string) => Promise<void>;
  createRequiredDocument: (newDocument: RequiredDocument) => Promise<void>;
  fetchDocumentTypes: () => Promise<void>;
  addFileToPreview: (file: FileType) => void;
  removeFileFromPreview: (file: FileType) => void;
  setSelectedFile: (file: FileType | null) => void;
  resetOpenedFiles: () => void;
  setLoanSummaryExpanded: () => void;
}

/**
 * This is the context that will be used to manage the state of the required documents
 * The state is managed using the reducer and the initial state is defined in the reducer file
 * The context will only make the state and dispatch available to the components that are wrapped with the Provider
 */

export const RequiredDocumentsContext = createContext<RequiredDocumentsContextProps>({} as RequiredDocumentsContextProps);

export const RequiredDocumentsProvider = ({ children }: RequiredDocumentsProviderProps) => {

  const [state, dispatch] = useReducer<React.Reducer<RequiredDocumentsState, RequiredDocumentsActions>>(
    requiredDocumentsReducer,
    initialState as RequiredDocumentsState
  );

  const fetchRequiredDocuments = async (appId: string) => {
    const requiredDocuments = await getApplicationRequiredDocuments(appId);
    dispatch({ type: SET_REQUIRED_DOCUMENTS, payload: { requiredDocuments } });
  };

  const createRequiredDocument = async (newDocument: RequiredDocument) => {
    const newDoc = await createRequiredDocumentService(newDocument);
    dispatch({ type: ADD_REQUIRED_DOCUMENT, payload: { requiredDocument: newDoc } });
  };

  const fetchDocumentTypes = async () => {
    try {
      const documentTypes = await fetchDocumentTypesService();
      const activeDocs: DocumentType[] = documentTypes.filter((doc: DocumentType) => doc.is_active);
      activeDocs.sort((a, b) => a.name.localeCompare(b.name));

      let dTypes: Record<DocumentType['id'], CustomDocumentType> = {};
      for (const docType of activeDocs) {
        dTypes[docType.id] = {
          name: docType.name,
          isRequired: docType.is_required,
        };
      }

      // inactive documents at the bottom, sorted by name
      let inactiveDocs: DocumentType[] = documentTypes.filter((doc) => !doc.is_active);
      inactiveDocs.sort((a, b) => a.name.localeCompare(b.name));
      for (const docType of inactiveDocs) {
        dTypes[docType.id] = {
          name: `[Inactive] ${docType.name}` as string,
          isRequired: docType.is_required,
        };
      }
      dispatch({ type: SET_DOCUMENT_TYPES, payload: { documentTypes: dTypes } });
    } catch (err) {
      console.error('Failed to fetch document types:', err);
    }
  };

  const setSelectedFile = (file: FileType | null) => {
    dispatch({ type: SET_SELECTED_FILE, payload: { selectedFile: file } });
  };

  const addFileToPreview = (file: FileType) => {
    if (!state.openedFiles.find((f) => f.id === file.id)) {
      dispatch({ type: SET_OPENED_FILES, payload: { openedFiles: [...state.openedFiles, file] } });
    }
    setSelectedFile(file);
  };

  const removeFileFromPreview = (file: FileType) => {
    dispatch({ type: SET_OPENED_FILES, payload: { openedFiles: state.openedFiles.filter((f) => f.id !== file.id) } });
    const defaultSelectedFile = state.openedFiles.length ? state.openedFiles[state.openedFiles.length - 1] : null;
    setSelectedFile(defaultSelectedFile);
  };

  const resetOpenedFiles = () => {
    dispatch({ type: SET_OPENED_FILES, payload: { openedFiles: [] } });
    setSelectedFile(null);
  };

  const setLoanSummaryExpanded = () => {
    dispatch({ type: SET_LOAN_SUMMARY_EXPANDED, payload: { loanSummaryExpanded: state.loanSummaryExpanded } });
  };

  return (
    <RequiredDocumentsContext.Provider value={{
      state,
      dispatch,
      fetchRequiredDocuments,
      createRequiredDocument,
      fetchDocumentTypes,
      addFileToPreview,
      removeFileFromPreview,
      setSelectedFile,
      resetOpenedFiles,
      setLoanSummaryExpanded,
    }}>
      {children}
    </RequiredDocumentsContext.Provider>
  );
}

export const useRequiredDocuments = () => {
  const context = useContext(RequiredDocumentsContext);
  if (!context) {
    throw new Error('useRequiredDocuments must be used within a RequiredDocumentsProvider');
  }
  return context;
}
