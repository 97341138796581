import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { DataKeyVal } from './DataKeyVal';
import { SFLink } from './SFLink';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';

export const BankLoanSummary = ({ loanSummary, loanId }) => {
  const { state: { loanSummaryExpanded }, setLoanSummaryExpanded } = useRequiredDocuments();

  return (
    <Box className="flex-column"
      sx={{
        position: 'fixed',
        zIndex: 1001,
        transition: 'none',
        transitionDuration: '0s',
        width: '100%',
      }}
      id="bankLoanSummaryHeader"
    >
      <Collapse
        in={loanSummaryExpanded}
        collapsedSize={20}
        sx={{
          borderBottom: '1px solid #D4D6D9',
          transition: 'none',
          transitionDuration: '0s',
          width: '100%',
          '& .MuiCollapse-root': {
            transition: 'none',
            transitionDuration: '0s',
          },
        }}
      >
        <Box
          className="flex-row"
          sx={{
            backgroundColor: '#F4F6F9', width: '100%', display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          <Box
            sx={{ width: '80%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                minHeight: '5rem',
                justifyContent: 'start',
                pt: '1rem',
                pb: '.5rem',
              }}
            >
              {loanSummary.map((data) => (
                <DataKeyVal
                  key={data.key}
                  label={data.label}
                  value={data.value ?? '--'}
                  button={data.label.toLowerCase().includes('status')}
                />
              ))}
            </Toolbar>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SFLink entityId={loanId} />
          </Box>
        </Box>
      </Collapse>
      <Box className="flex-row" sx={{ mt: '-0.8rem', display: 'flex', justifyContent: 'center' }}>
        <IconButton
          sx={{
            bgcolor: 'white',
            p: '0',
            boxShadow: 'grey 0px 0px 4px',
          }}
          onClick={() => setLoanSummaryExpanded()}
        >
          {loanSummaryExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
    </Box>
  );
};
