import { SFLoanApplication } from '@backend/types/Loan';
import { Error } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import BulkCreateDocumentsModal from '../../components/BulkCreateDocumentsModal';
import CreateNewTemplateModal from '../../components/CreateNewTemplateModal';
import DocumentIssuesModal from '../../components/DocumentIssuesModal';
import DocumentsByWorkflowStepList from '../../components/DocumentsByWorkflowStepList';
import { FilePreviewDrawer } from '../../components/FilePreviewDrawer';
import RequiredDocumentModal from '../../components/RequiredDocumentModal';
import { useRequiredDocuments } from '../../contexts/RequiredDocumentsContext';
import { isAdmin } from '../../helpers/auth';
import { canRWFiles } from '../../helpers/featurePermissionRules';
import { RequiredDocument as RequiredDocumentType } from '../../helpers/types';
import { getUsers } from '../../services/usersService';
import { useWatchHeight } from '../../hooks/useWatchHeight';

export const FileManagement = () => {
  const app = useOutletContext() as SFLoanApplication;

  const appId = app.Id;
  const appName = app.Name;
  const showFileReadWriteButtons = canRWFiles();

  const { state: { requiredDocuments, openedFiles, loanSummaryExpanded }, fetchRequiredDocuments, createRequiredDocument, fetchDocumentTypes, resetOpenedFiles } = useRequiredDocuments();
  const loanSummaryHeight = useWatchHeight('bankLoanSummaryHeader', 100, [loanSummaryExpanded]);
  const [newRequirementModalOpen, setNewRequirementModalOpen] = useState(false);
  const [documentIssuesModalOpen, setdocumentIssuesModalOpen] = useState(false);
  const [createTemplateModalOpen, setCreateTemplateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [expandAllClicked, setExpandAllClicked] = useState<number>(0);
  const [collapseAllClicked, setCollapseAllClicked] = useState<number>(0);
  const [bulkCreateModalOpen, setBulkCreateModalOpen] = useState(false);
  const [filterBy, setFilterBy] = useState<string>('all');
  const [filterValue, setFilterValue] = useState<string>('');
  const filterValueRef = useRef<HTMLInputElement>(null);
  const [filePreviewDrawerOpen, setFilePreviewDrawerOpen] = useState(false);

  const toggleModal = (cb) => cb((prev) => !prev)

  const handleCreateRequiredDocument = async (document: RequiredDocumentType) => {
    document.parent_id = appId;
    await createRequiredDocument(document);
    await fetchRequiredDocuments(appId);
  };

  useEffect(() => {
    setLoading(true);
    fetchRequiredDocuments(appId);
    fetchDocumentTypes()

    // users are already sorted by name
    getUsers().then((rawUsers) => {
      let userObjects: any = {};
      for (const user of rawUsers) {
        userObjects[user.name] = {
          id: user.id,
          email: user.username,
        };
      }

      setUsers(userObjects);
    });
    setLoading(false);
  }, [appId]);

  const handleDownloadAll = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await fetch(
        `/api/v1/loans/required-documents/${appId}/zip`,
      );
      /* the below logic creates a download link and clicks it
      to download the file returned from the fetch request */
      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${appName}_Railway_LFM_RequiredDocuments.zip`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error: { message: string } | any) {
      console.error('Error downloading file:', error);
      setError(error.message);
    }
    setLoading(false);
  };

  const filteredDocuments: RequiredDocumentType[] = useMemo(() => {

    if (filterBy === 'all' || !filterValue) {
      return requiredDocuments;
    }
    return requiredDocuments.filter((doc) => {
      let valueToCheck = '';

      if (filterBy === 'owner') {
        // If filtering by owner, we allow users to filter by name or email
        valueToCheck = `${doc.owner?.name || ''} ${doc.owner?.username || ''}`.toLowerCase();
      } else if (filterBy === 'document_type') {
        // If filtering by document type, we allow users to filter by name or crb_abbrev
        valueToCheck = `${doc.document_type?.name || ''} ${doc.document_type?.crb_abbrev || ''}`.toLowerCase();
      } else if (filterBy === 'created_at') {
        const docDate = new Date(doc.created_at).toISOString().split('T')[0]; // Extract date part
        valueToCheck = docDate;
      } else if (filterBy === 'created_after_date') {
        const filterDate = new Date(filterValue);
        return new Date(doc.created_at) > filterDate;
      } else if (filterBy === 'created_before_date') {
        const filterDate = new Date(filterValue);
        return new Date(doc.created_at) < filterDate;
      } else {
        // If filtering by any other field, we allow users to filter by the value of that field
        valueToCheck = doc[filterBy]?.toString().toLowerCase() || '';
      }

      return valueToCheck.includes(filterValue.toLowerCase());
    });
  }, [requiredDocuments, filterBy, filterValue]);

  useEffect(() => {
    // Reset filter value
    setFilterValue('');
    // This is set to focus on the filter input when the filter by is changed
    if (filterBy !== 'all' && filterValueRef.current) {
      filterValueRef.current.focus();
    }
  }, [filterBy]);

  const handleCreateTemplate = async (template) => {
    try {
      const res = await axios.post('/api/v1/loans/template-document-types', template);

      await fetchRequiredDocuments(appId);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (openedFiles.length > 0) {
      setFilePreviewDrawerOpen(true)
    }
  }, [openedFiles])

  useEffect(() => {
    // this acts as a destructor of the component
    return () => {
      resetOpenedFiles();
      setFilePreviewDrawerOpen(false);
    }
  }, [])

  return (
    <>
      <Box className="content"
        sx={{
          px: '2.5rem', fontFamily: 'Lato, sans-serif', gap: 0,
          position: 'relative',
        }}
      >
        {loading || !filteredDocuments ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              width: filePreviewDrawerOpen ? '58%' : '100%',
              height: '100%',
            }}
          >
            <Box
              display="flex"
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ fontFamily: 'Lato, sans-serif', py: '1rem' }}
            >
              <Typography variant="h5"
                sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 500 }}
              >{`Documents (${filteredDocuments.length})`}</Typography>
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '.5rem', }}
              >
                {showFileReadWriteButtons ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => toggleModal(setNewRequirementModalOpen)}
                      color="inherit"
                      sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif' }}
                    >
                      Create Custom New Document
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => toggleModal(setBulkCreateModalOpen)}
                      color="inherit"
                      sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif' }}
                    >
                      Bulk Create Documents From Template
                    </Button>
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif' }}
                      disabled={loading}
                      onClick={handleDownloadAll}
                    >
                      {loading ? (
                        <Box
                          display="flex"
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <CircularProgress
                            size=".875rem"
                            sx={{ mr: '.5rem' }}
                            color="inherit"
                          />
                          <Typography>Downloading ...</Typography>
                        </Box>
                      ) : (
                        'Download All'
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => toggleModal(setdocumentIssuesModalOpen)}
                      color="inherit"
                      sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif' }}
                    >
                      View List of Issues
                    </Button>
                    {isAdmin() && (
                      <Button
                        variant="outlined"
                        onClick={() => toggleModal(setCreateTemplateModalOpen)}
                        color="inherit"
                        sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif' }}
                      >
                        Create Template
                      </Button>
                    )}
                  </>
                ) : null}
              </Box>
              {error.length > 0 ? (
                <Box className="flex-row-end flex-gap-0_5">
                  <Error color="error" />
                  <Typography color="error">
                    Something went wrong downloading your files
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box
              display="flex"
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ mb: '1rem' }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '.5rem', width: 1 }}
              >
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '.5rem', justifyContent: 'space-between' }}
                >
                  <Typography
                    onClick={() => setExpandAllClicked(prev => prev + 1)}
                    sx={{
                      textTransform: 'none',
                      fontFamily: 'Lato, sans-serif',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontWeight: 100
                    }}
                  >
                    Expand all groups
                  </Typography>
                  <Typography
                    onClick={() => setCollapseAllClicked(prev => prev + 1)}
                    sx={{
                      textTransform: 'none',
                      fontFamily: 'Lato, sans-serif',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontWeight: 100
                    }}
                  >
                    Collapse all groups
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', my: 1 }}>
                    <TextField
                      select
                      label="Filter by"
                      id="filter-by"
                      value={filterBy}
                      onChange={(e) => setFilterBy(e.target.value)}
                      fullWidth
                      sx={{
                        fontFamily: 'Lato, sans-serif',
                        width: '12rem',
                        '& .MuiSelect-select': {
                          py: '5px',
                        },
                      }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true, // This ensures the label is always at the top
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="document_approval_source">Approval Source</MenuItem>
                      <MenuItem value="created_after_date">Created After Date</MenuItem>
                      <MenuItem value="created_at">Created At </MenuItem>
                      <MenuItem value="created_before_date">Created Before Date</MenuItem>
                      <MenuItem value="created_by">Created By</MenuItem>
                      <MenuItem value="document_type">Document Type</MenuItem>
                      <MenuItem value="owner">Owner</MenuItem>
                      <MenuItem value="required_for_step">Required For Step</MenuItem>
                      <MenuItem value="approval_status">Status</MenuItem>
                    </TextField>
                    {/* Show input field if filtering by anything other than 'all' */}
                    {filterBy !== 'all' && (
                      <TextField
                        id="filter-input"
                        label={`Filter ${filterBy.replace(/_/g, ' ')}`}
                        variant="outlined"
                        sx={{
                          fontFamily: 'Lato, sans-serif',
                          '& .MuiInputBase-input': {
                            py: '5px',
                          },
                          '& .MuiInputLabel-root': {
                            top: '-10px',
                          },
                          // This is for the label to shrink and move up when the input is focused
                          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
                            transform: 'translate(14px, 1px) scale(.75)',
                          },
                        }}
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        inputRef={filterValueRef}
                        placeholder={filterBy === 'created_after_date' || filterBy === 'created_before_date' ? 'YYYY, YYYY-MM-DD' : ''}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <DocumentsByWorkflowStepList
              applicationId={appId}
              docs={filteredDocuments}
              users={users}
              expandAllClicked={expandAllClicked}
              collapseAllClicked={collapseAllClicked}
              isFiltering={filterBy !== 'all' && filterValue}
            />
            <RequiredDocumentModal
              open={newRequirementModalOpen}
              onClose={() => toggleModal(setNewRequirementModalOpen)}
              onSubmit={handleCreateRequiredDocument}
              users={users}
            />
            <DocumentIssuesModal
              open={documentIssuesModalOpen}
              onClose={() => toggleModal(setdocumentIssuesModalOpen)}
            />
            <BulkCreateDocumentsModal
              open={bulkCreateModalOpen}
              onClose={() => toggleModal(setBulkCreateModalOpen)}
            />
            <CreateNewTemplateModal
              open={createTemplateModalOpen}
              onClose={() => toggleModal(setCreateTemplateModalOpen)}
              onSubmit={handleCreateTemplate}
            />
          </Box>
        )}
        {filePreviewDrawerOpen && (
          <FilePreviewDrawer
            isDrawerOpen={filePreviewDrawerOpen}
            toggleDrawer={() => setFilePreviewDrawerOpen(!filePreviewDrawerOpen)}
            customSx={{
              height: '100vh',
              position: 'fixed',
              top: 0,
              bottom: 'unset',
              zIndex: 20,
              marginTop: `${loanSummaryHeight+60}px`,
            }}
          />
        )}
      </Box>
    </>
  );
};