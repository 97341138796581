import React from 'react';
import{ Box, Typography } from '@mui/material';
import { Note } from "../../helpers/types";
import { NoteType } from '../../helpers/constants';

interface NotesTooltipInfoProps {
    notes: Note[];
}

export const NotesTooltipInfo = ({
    notes
}: NotesTooltipInfoProps) => {

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', borderRight: '1px solid #e0e0e0', pr: '1rem', width: '50%', height: '100%' }}>
                <Typography variant="body2" color={'text.secondary'} fontWeight={700}>
                    Internal Note:
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                    {
                        notes
                            .filter(note => note.note_type === NoteType.Internal)
                            .map(note => Array.isArray(note) ? note[0]?.body : note.body)[0] || '-'
                    }
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', pl: '1rem', width: '50%' }}>
                <Typography variant="body2" color={'text.secondary'} fontWeight={700}>
                    External Note:
                </Typography>
                <Typography variant="body2" color={'text.secondary'}>
                    {
                        notes
                            .filter(note => note.note_type === NoteType.External)
                            .map(note => Array.isArray(note) ? note[0]?.body : note.body)[0] || '-'
                    }
                </Typography>
            </Box>
        </Box>
    );
}