import { ArrowBack } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toCurrency } from '../../helpers/converters';
import React from 'react';

/**
 * @typedef {import('../../../../backend/src/types/FundedProject').SFFundedProject} SFFundedProject
 */

/**
 * Displays the project details including
 * - Loan Number
 * - Borrower Business Name
 * - Owner @todo - this is not yet implemented, needs to be fetched as user from SF
 * - Installer Account @todo - this is not yet implemented, needs to be fetched as account from SF
 * - Milestone - current milestone
 * As well as contols to go back to the project list and view the project in Salesforce
 * @param {Object} props
 * @param {SFFundedProject} props.project
 */
export const ProjectDetailsBar = ({ project }) => {
  const navigate = useNavigate();
  const {
    Loan_App_Number__c,
    Loan_App_Loan_Amount__c,
    Borrower_Business_Name__c,
    Active_Milestone__c,
    owner,
    installerBusinessName,
  } = project;

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <AppBar
      position="sticky"
      color="default"
      sx={{
        width: '100%',
        mt: '73px',
        mb: 2,
        position: 'fixed',
        top: 0,
        zIndex: 1000,
        transition: 'none',
      }}
    >
      <Toolbar
        sx={{
          bgcolor: '#F4F6F9',
          transition: 'none',
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mr: 10 }}
          onClick={handleBackClick}
        >
          <ArrowBack />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'start',
            '&>*': {
              mx: 4,
            },
          }}
        >
          <Box>
            <Typography variant="body2" color={'#7B828A'}>
              Loan Number
            </Typography>
            <Typography variant="body1" fontSize={16}>
              {Loan_App_Number__c}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color={'#7B828A'}>
              Loan Amount
            </Typography>
            <Typography variant="body1" fontSize={16}>
              {toCurrency(Loan_App_Loan_Amount__c)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color={'#7B828A'}>
              Borrower Business Name
            </Typography>
            <Typography variant="body1" fontSize={16}>
              {Borrower_Business_Name__c}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color={'#7B828A'}>
              Owner
            </Typography>
            <Typography variant="body1" fontSize={16}>
              {owner.Name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color={'#7B828A'}>
              Installer Account
            </Typography>
            <Typography variant="body1" fontSize={16}>
              {installerBusinessName}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" color={'#7B828A'}>
              Milestone
            </Typography>
            <Typography variant="body1" fontSize={16}>
              {Active_Milestone__c}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
