import { SFLoanApplication } from '@backend/types/Loan';
import { Box } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { BankLoanSummary } from '../components/BankLoanSummary';
import { Header } from '../components/Header';
import { BankLoanSideNav } from '../components/navigation/BankLoanSideNav';
import { toCurrency } from '../helpers/converters';
import { useWatchHeight } from '../hooks/useWatchHeight';
import { useRequiredDocuments } from '../contexts/RequiredDocumentsContext';

const loanSummaryKeys = [
  { key: 'Name', label: 'Loan ID' },
  { key: 'Borrower_Business_Name__c', label: 'Borrower Business Name' },
  { key: 'loan_amount__c', label: 'Loan Amount', type: 'money' },
  { key: 'Submission_Date_Stage2__c', label: 'Submission Date' },
  { key: 'Sys_Prop_Address_City__c', label: 'City' },
  { key: 'Sys_Prop_Address_State__c', label: 'State' },
  { key: 'Internal_Status__c', label: 'Internal Status' },
  { key: 'Installer_Business_Name__c', label: 'Installer' },
];

interface StatusUpdates {
  Id: number;
  LoanId: string;
  Status: string;
  TimeStamp: string;
}
export type CombinedApp = SFLoanApplication & {
  bankLoan: {
    StatusUpdates: StatusUpdates[];
    FundingInfoUpdates: any[];
    FundingAttempts: any[];
    LoanInfoUpdates: any[];
    ProcessedRails: any[];
    Attachments: any[];
  };
} & {
  childLoanGUIDs: string[];
} & { childLoans: any[] } & {
  bank_error: any;
} & {
  railwayActions: {
    id: number;
    application_id: string;
    bank_loan_guid: string;
    action: string;
    loan_data: any;
    date: string;
  }[];
};

export const fetchLoanApplication = async ({ params }) => {
  let data: CombinedApp;
  try {
    const sfApp = await axios(`/api/v1/loans/application/${params.appId}`);
    data = sfApp.data;
  } catch (err: any) {
    return {
      error: {
        message: err.message ?? 'Error occurred while loading the application',
        title: err.title ?? 'Error loading the application',
      },
    };
  }
  try {
    const crbId = data['CRB_Application_ID__c'];
    if (crbId) {
      const bankLoan = await axios(
        `/api/v1/loans/${crbId}?fields=FundingInfoUpdates,FundingAttempts,LoanInfoUpdates,StatusUpdates,ProcessedRails,Attachments`,
      );
      data = { ...data, bankLoan: bankLoan.data };
    }
    if (data.childLoanGUIDs && data.childLoanGUIDs.length > 0) {
      const childLoans = [] as any[];
      for (const guid of data.childLoanGUIDs) {
        if (guid) {
          try {
            const loc = await axios(
              `/api/v1/loans/loc/${guid}?fields=FundingInfoUpdates,FundingAttempts,LoanInfoUpdates,StatusUpdates,ProcessedRails,Attachments`,
            );
            childLoans.push(loc.data);
          } catch (err) {
            console.error(err);
          }
        }
      }
      if (childLoans.length > 0) {
        data = { ...data, childLoans };
        // if there are child loans (LOC) combine their data with the original loan
        data.bankLoan.FundingInfoUpdates = [
          ...data.bankLoan.FundingInfoUpdates,
          ...childLoans.flatMap((loan) => loan.FundingInfoUpdates),
        ];
        data.bankLoan.FundingAttempts = [
          ...data.bankLoan.FundingAttempts,
          ...childLoans.flatMap((loan) => loan.FundingAttempts),
        ];
        data.bankLoan.LoanInfoUpdates = [
          ...data.bankLoan.LoanInfoUpdates,
          ...childLoans.flatMap((loan) => loan.LoanInfoUpdates),
        ];
        data.bankLoan.StatusUpdates = [
          ...data.bankLoan.StatusUpdates,
          ...childLoans.flatMap((loan) => loan.StatusUpdates),
        ];
        data.bankLoan.ProcessedRails = [
          ...data.bankLoan.ProcessedRails,
          ...childLoans.flatMap((loan) => loan.ProcessedRails),
        ];
        data.bankLoan.Attachments = [
          ...data.bankLoan.Attachments,
          ...childLoans.flatMap((loan) => loan.Attachments),
        ];
      }
    }
  } catch (err: any) {
    return {
      ...data,
      bank_error: {
        message: err.message ?? 'Error occurred while loading CRB bank loan',
        title: err.title ?? 'Error loading the bank loan',
      },
    };
  }

  return data;
};

export const LoanApplicationLayout = () => {
  // It's used to force the render when loanSummary changes its height
  const { state: { loanSummaryExpanded } } = useRequiredDocuments();

  const loanData = useLoaderData() as SFLoanApplication | any;
  let loanSummary = loanSummaryKeys.map((data) => ({
    key: data.key,
    label: data.label,
    value:
      data.type === 'money'
        ? toCurrency(loanData[data.key])
        : loanData[data.key],
  }));

  // Insert the years and interest rate after loan amount in the header
  loanSummary = [
    ...loanSummary.slice(0, 3),
    {
      key: 'years',
      label: 'Years',
      value: `${
        loanData.loanProduct
          ? loanData.loanProduct.Loan_Term_Years__c + 'Years'
          : '--'
      }`,
    },
    {
      key: 'apr',
      label: 'Interest Rate',
      value: `${
        loanData.loanProduct
          ? loanData.loanProduct.APR_Percentage__c + '%'
          : '--'
      }`,
    },
    ...loanSummary.slice(3),
  ];
  const loanSummaryHeight = useWatchHeight('bankLoanSummaryHeader', 100, [loanSummaryExpanded]);

  return (
    <Box>
      <Header />
      <Box sx={{ display: 'flex', minHeight: '88.5vh', marginTop: '73px' }}>
        <BankLoanSideNav />
        <Box sx={{ bgcolor: '#ECECEC', width: '100%' }}>
          <BankLoanSummary loanSummary={loanSummary} loanId={loanData.Id} />
          <Box sx={{ mt: `${loanSummaryHeight}px`}}>
            <Outlet context={loanData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
